<template>
  <div class="row m-0 px-lg-4">
    <div class="col-12 px-lg-5">
      <div class="row m-0">
        <!--CONFIRMATIONS PAGE-->
        <div class="col-12 p-0" v-if="documentsActivePage === 'confirmations'">
          <confirmations></confirmations>
        </div>
        <!--END CONFIRMATIONS PAGE-->
        <!--ITINERARIES PAGE-->
        <div class="col-12 p-0" v-if="documentsActivePage === 'itineraries'">
          <itineraries></itineraries>
        </div>
        <!--END ITINERARIES PAGE-->
        <!--LODGE INFO PAGE-->
        <div class="col-12 p-0" v-if="documentsActivePage === 'lodgeInfo'">
          <lodge-info></lodge-info>
        </div>
        <!--END LODGE INFO PAGE-->
        <!--ADDITIONAL INFO PAGE-->
        <div class="col-12 p-0" v-if="documentsActivePage === 'additionalInfo'">
          <additional-info></additional-info>
        </div>
        <!--END ADDITIONAL INFO PAGE-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    additionalInfo: () => import('@/components/AdditionalInfo'),
    confirmations: () => import('@/components/Confirmations'),
    itineraries: () => import('@/components/Itineraries'),
    lodgeInfo: () => import('@/components/LodgeInfo')
  },
  props: ['documentsActivePage'],
  data () {
    return {
    }
  }
}
</script>
<style scoped>
.doc_icn {
  width: 110px;
  height: 110px;
}
</style>
